  export const KEYS = {
    MAIL_ACCESS_KEY : "04eb780c-2c9d-403a-878f-456931c82d65"
  } 

  export const KVVKK = {
    KVKK_TITLE : "6698 SAYILI KİŞİSEL VERİLERİN KORUNMASI KANUNU KAPSAMINDA AYDINLATMA METNİ",
    KVKK1 : `ÖZEL LİVA AKADEMİ ANAOULU olarak kişisel verilerin korunmasına ve özel hayatın gizliliğine büyük önem vermekteyiz. Bu çerçevede, 6698 sayılı Kişisel Verilerin Korunması Kanunu gereğince kişisel verilerinizin işlenmesi ve korunması konusundaki haklarınız hakkında sizleri bilgilendirmek isteriz. Şirket olarak kişisel verilerinizi, 6698 sayılı KVKK ve ilgili diğer mevzuat çerçevesinde veri sorumlusu sıfatıyla işlemekteyiz.`,
    KVKK3 : `Kişisel verileriniz, ilgili mevzuat ve iş süreçleri gereği gerekli olan süre boyunca saklanacaktır. Saklama süresi dolduğunda veya veri işlenme amacının ortadan kalkması durumunda kişisel verileriniz güvenli bir şekilde silinecek veya anonim hale getirilecektir.`,
    KVKK2 : ``,
    KVKK4 : `KVKK’nın 11. maddesi çerçevesinde kişisel verilerinizle:
            Kişisel verilerinizin işlenip işlenmediğini öğrenme,
            Kişisel verileriniz işlenmişse buna ilişkin bilgi talep etme,
            Kişisel verilerinizin işlenme amacını öğrenme ve amacına uygun kullanılıp kullanılmadığını öğrenme,
            Kişisel verilerinizin eksik veya yanlış olması durumunda düzeltilmesini isteme,
            Kişisel verilerinizin silinmesini veya yok edilmesini talep etme,
            Kişisel verilerinizin üçüncü kişilere aktarılmasını talep etme,
            Kişisel verilerinizin işlenmesine itiraz etme,
            Kişisel verilerinizin otomatik sistemlerle analiz edilmesi sonucu meydana gelen sonuçlardan etkilenmeyi önleme. haklara sahipsiniz`,
    KVKK5 : `Kişisel verilerinizle ilgili taleplerinizi, livaakademianaokulu@gmail.com adresine iletebilirsiniz. Başvurularınız, yasal süreler içinde değerlendirilecek ve gerekli geri dönüşler yapılacaktır.
            Şirket olarak kişisel verilerinizin korunmasına yönelik tüm gerekli tedbirleri alarak, veri güvenliğinizi sağlamak için çalışmaktayız. KVKK ve diğer mevzuat çerçevesinde kişisel verilerinizin korunması konusunda sizlere şeffaf bilgi sağlamaktan memnuniyet duyarız.` 
  }