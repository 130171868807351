import React from "react";
import styles from "./footer.module.css";
import { useLocation } from "react-router-dom";
import endpoint from '../../common/endpoint'

const Footer = () => {

  const { pathname } = useLocation();

  const isHomePage = pathname === "/";

  if (isHomePage) {
    return null;
  }


  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <div className={styles.section}>
          <h2 className={styles.title}>Özel Liva Akademi Anaokulu</h2>
          <p className={styles.text}>Eğitimde öncü, sevgi dolu bir gelecek için buradayız.</p>
        </div>
        <div className={styles.section}>
          <h3 className={styles.subtitle}>İletişim</h3>
          <p className={styles.text}> Adres: 
            <a href={endpoint.ADRESS}
            target="_blank" rel="noopener noreferrer" 
            className={styles.link}>
              Cumhuriyet Mahallesi, 148. Sokak, No:14, Ceyhan / Adana
            </a>
          </p>
          <p className={styles.text}>
            Telefon: <a href={endpoint.TEL_NO} className={styles.link}>0 (545) 391 8528</a>
          </p>
          <p className={styles.text}>
            Email: <a href={endpoint.MAIL_ADRESS} className={styles.link}>livaakademianaokulu@gmail.com</a>
          </p>
        </div>
        <div className={styles.section}>
          <h3 className={styles.subtitle}>Sosyal Medya</h3>
          <p className={styles.text}>
            <a href="https://facebook.com" className={styles.link}>Facebook</a> | 
            <a href="https://twitter.com" className={styles.link}> Twitter</a> | 
            <a href={endpoint.INSTAGRAM} className={styles.link}> Instagram</a>
          </p>
        </div>
      </div>
      <div className={styles.copyright}>
        <p>&copy; 2024 Özel Liva Akademi Anaokulu. Tüm hakları saklıdır.</p>
      </div>
    </footer>
  );
}

export default Footer;