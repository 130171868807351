import React from 'react'
import styles from './imageContent.module.css'

interface ImageContentProps {
    title: string,
    content: string,
    image: string,
}

const ImageContent = ({title, content, image}: ImageContentProps) => {
  return (
    <div className={styles.content}>
      <img className={styles.image} alt="wewe" src={image} />
    <div className={styles.text}>
      <div>
        <h2 className={styles.title}>{title}</h2>
        <span>{content}</span>
      </div>
    </div>
  </div>
  )
}

export default ImageContent
