// image imports
import vector from './images/vector.png'
import img1 from './images/img1.jpeg'
import img2 from './images/img2.jpeg'
import img3 from './images/img3.jpeg'
import img4 from './images/img4.jpeg'
import img5 from './images/img5.jpeg'
import img6 from './images/img6.jpeg'
import img7 from './images/img7.jpeg'
import logo from './images/logo.jpeg'
import logoNoBackground from './images/logoNoBackground.png'

// svg imports
import hommeIcon from './svg/homeIcon.svg'
import menu from './svg/menu-svgrepo-com.svg'

export const images = { 
    vector,
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    logo,
    logoNoBackground
 };

export const svg = { hommeIcon, menu }