import { createBrowserRouter } from "react-router-dom";
import MainLayout from "./layouts/mainLayout";
import AboutUs from "../pages/aboutUs";
import Contact from "../pages/contact";
import HomePage from "../pages/homePage";
import Academic from "../pages/academic";

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout Container={HomePage} />,
  },
  {
    path: "/academic",
    element: <MainLayout Container={Academic} />,
  },
  {
    path: "/about-us",
    element: <MainLayout Container={AboutUs} />,
  },
  {
    path: "/contact",
    element: <MainLayout Container={Contact} />,
  },
]);

export default router;