import React from "react";
import styles from "./abouts.module.css";
import { aboutUsLiva } from "../../common/strings";

import { images } from "../../assets";

const AboutUs = () => {

  return <div className={styles.container}>
    <div className={styles.content}>
      <div>
        <img className={styles.image} src={images.img3} alt="Tein" />
      </div>
      <div className={styles.text}>
        <h1>Hakkımızda</h1>
        <p>{aboutUsLiva}</p>
      </div>
    </div>
    <div className={styles.counter}>
      <div className={styles.count}>
        <span>46+</span>
        <h4>ÖĞRENCİ</h4>
      </div>
      <div className={styles.count}>
        <span>7+</span>
        <h4>DENEYİM</h4>
      </div>
      <div className={styles.count}>
        <span>5+</span>
        <h4>KADRO</h4>
      </div>
    </div>
  </div>;
}

export default AboutUs;
