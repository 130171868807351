import React, { useState } from "react";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import styles from "./headerMenuBar.module.css";
import { images, svg } from "../../assets";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const HeaderMenuBar = () => {
  const [menuStatus, setMenuStatus] = useState(false);
  const [navbar, setNavbar] = useState(false);

  const location = useLocation();

  const changeBackground = () => {
    if (location.pathname === '/') {
      setNavbar(true);
      document.body.style.overflow = 'hidden';
    } else {
      setNavbar(false);
      document.body.style.overflow = 'auto';
    }
  };

  React.useEffect(() => {
    changeBackground();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <div className={navbar ? styles.container : styles.containerActive}>
      <div>
        <Link to="/">
          <img className={styles.logo} alt="logo" src={images.logoNoBackground} />
        </Link>
      </div>
      <div onClick={() => setMenuStatus(prev => !prev)} className={styles.mobileMenuIcon}>
        <img alt="menu" src={svg.menu} />
      </div>
      <div className={styles.menuLinks}>
        <div className={navbar ? styles.menuLink : styles.menuLinkActive}>
          <Link to="/">
            <span>ANASAYFA</span>
          </Link>
        </div>
        <div className={navbar ? styles.menuLink : styles.menuLinkActive}>
          <Link to="/academic">
            <span>AKADEMİK</span>
          </Link>
        </div>
        <div className={navbar ? styles.menuLink : styles.menuLinkActive}>
          <Link to="/about-us">
            <span>HAKKIMIZDA</span>
          </Link>
        </div>
        <div className={navbar ? styles.menuLink : styles.menuLinkActive}>
          <Link to="/contact">
            <span>İLETİŞİM</span>
          </Link>
        </div>
      </div>
      {menuStatus && (
        <div className={styles.modal} onClick={() => setMenuStatus(false)}>
          <div className={styles.modalContent} onClick={e => e.stopPropagation()}>
            <div className={styles.modalContainer}>
              <FontAwesomeIcon icon={faCircleXmark} size="2x"  onClick={() => setMenuStatus(false)}/>
            <span>Özel Liva Akademi Anaokulu</span>
            </div>
            <div className={styles.modalLink}>
              <Link to="/" onClick={() => setMenuStatus(false)}>
                <span>Anasayfa</span>
              </Link>
            </div>
            <div className={styles.modalLink}>
              <Link to="/academic" onClick={() => setMenuStatus(false)}>
                <span>Akademik</span>
              </Link>
            </div>
            <div className={styles.modalLink}>
              <Link to="/about-us" onClick={() => setMenuStatus(false)}>
                <span>Hakkımızda</span>
              </Link>
            </div>
            <div className={styles.modalLink}>
              <Link to="/contact" onClick={() => setMenuStatus(false)}>
                <span>İletişim</span>
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HeaderMenuBar;
