import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp, faInstagram } from "@fortawesome/free-brands-svg-icons";
import styles from "./contactIcon.module.css";
import endpoint from "../../common/endpoint";

const ContactIcons: React.FC = () => {
  return (
    <div className={styles.iconContainer}>
      <a href={endpoint.TEL_NO} className={styles.icon} title="Call Us">
        <FontAwesomeIcon icon={faPhone} />
      </a>
      <a
        href={endpoint.WHATSAPP}
        className={styles.icon}
        target="_blank"
        rel="noopener noreferrer"
        title="Chat on WhatsApp"
      >
        <FontAwesomeIcon icon={faWhatsapp} />
      </a>
      <a
        href={endpoint.INSTAGRAM}
        className={styles.icon}
        target="_blank"
        rel="noopener noreferrer"
        title="Follow us on Instagram"
      >
        <FontAwesomeIcon icon={faInstagram} />
      </a>
    </div>
  );
};

export default ContactIcons;
