import React, { FormEvent, useEffect, useRef, useState } from "react";
import styles from "./contact.module.css";
import Swal from "sweetalert2";

import endpoint from "../../common/endpoint";
import { KEYS, KVVKK } from "../../common/constants";

const Contact = () => {
  const [kvkk, setKvkk] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);

  const openKvkk = () => {
    setKvkk(true);
  };

  const closeKvkk = () => {
    setKvkk(false);
  };

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);

    formData.append("access_key",  KEYS.MAIL_ACCESS_KEY);

    const object = Object.fromEntries(formData);
    const json = JSON.stringify(object);

    const res = await fetch(endpoint.SEND_MAIL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      },
      body: json
    }).then((res) => res.json());

    if (res.success) {
      Swal.fire({
        title: "Mesajınız Gönderildi",
        text: "İletişim talebiniz başarıyla iletildi. Size en kısa sürede dönüş yapacağız.",
        icon: "success"
      });
      if (formRef.current) {
        formRef.current.reset();
      }
    }
  };

  useEffect(() => {
    if (kvkk) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    return () => {
      document.body.style.overflow = '';
    };
  }, [kvkk]);


  return <div className={styles.container}>
    <div className={styles.title}>
      <h1>Bize Mail Gönderin, Size Ulaşalım!</h1>
    </div>
    <section className={styles.contact}>
      <form ref={formRef} onSubmit={onSubmit}>
        <div className={styles.inputBox}>
          <input name="name" type="text" className={styles.field} placeholder="Ad Soyad" required />
        </div>
        <div className={styles.inputBox}>
          <input name="email" type="email" className={styles.field} placeholder="E-posta" required />
        </div>
        <div className={styles.inputBox}>
          <input name="phone" type="tel" className={styles.field} placeholder="0 (XXX) XXX XX XX" required />
        </div>
        <div className={styles.inputBox}>
          <textarea name="message" className={styles.fieldMess} placeholder="Mesaj" required></textarea>
        </div>
        <div className={styles.checkbox}>
          <input type="checkbox" required />
          <label><span onClick={openKvkk}>KVKK Metni</span>'ni okudum, onaylıyorum.</label>
        </div>
        <div>
          <button type="submit">Gönder</button>
        </div>
      </form>
    </section>
    {kvkk &&
      <div className={styles.modal} onClick={closeKvkk}>
        <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
          <span className={styles.closeButton} onClick={closeKvkk}>&times;</span>
          <div>
            <h1>{KVVKK.KVKK_TITLE}</h1>
            <p>{KVVKK.KVKK1}</p>
            <h2>Kişisel Verilerinizin Saklanma Süresi</h2>
            <p>{KVVKK.KVKK3}</p>
            <h2>Kişisel Verilerinize İlişkin Haklarınız</h2>
            <p>{KVVKK.KVKK4}</p>
            <h1>İletişim ve Başvuru</h1>
            <p>{KVVKK.KVKK5}</p>
          </div>
        </div>
      </div>
    }
  </div>
}

export default Contact;