import React from "react";
import styles from "./academic.module.css";
import ImageContent from "../../components/image-content";

import { images } from "../../assets";


const Academic = () => {
  return <div className={styles.container}>
    <ImageContent
      title="Akıl ve Zeka Oyunları"
      content="Çocukların yaratıcılıklarını geliştirebileceği ve öğrenmeyi eğlenceli hale getiren çeşitli uygulamalı etkinlikler sunuyoruz."
      image={images.img1}
    />  
    <ImageContent
      title="İngilizce"
      content="Küçük yaşlardan itibaren İngilizceye erken bir başlangıç yaparak, dil becerilerini geliştirmeleri için eğlenceli ve etkileşimli aktiviteler sunuyoruz."
      image={images.img3}
    />
    <ImageContent
      title="Matematik"
      content="Matematiksel düşünme yetilerini geliştirmek için oyunlar ve uygulamalı aktivitelerle çocukların temel matematik kavramlarını öğrenmelerini sağlıyoruz."
      image={images.img1}
    />
    <ImageContent
      title="Spor"
      content="Fiziksel gelişimi destekleyen, eğlenceli ve enerjik spor aktiviteleriyle çocukların hareket becerilerini ve takım çalışmasını teşvik ediyoruz."
      image={images.img3}
    />
    <ImageContent
      title="Jimnastik"
      content="Çocukların denge, koordinasyon ve esneklik gibi temel motor becerilerini geliştirmeleri için çeşitli jimnastik hareketleri ve egzersizler sunuyoruz."
      image={images.img6}
    />
  </div>
}

export default Academic;