import React, { useEffect, useState } from "react";
import styles from "./homePage.module.css";
import { Link } from "react-router-dom";
import video from "../../assets/videos/Children_video.mp4";
import ContactIcons from "../../components/contactIcons";

const HomePage: React.FC = () => {
  interface TextCharacter {
    char: string;
    index: number;
    visible?: boolean;
  }
  const [currentWordIndex, setCurrentWordIndex] = useState<number>(0);
  const [displayText, setDisplayText] = useState<TextCharacter[]>([]);

  useEffect(() => {
    const words = [
      "Çocuğunuzun Geleceğine Yatırım Yapın!",
      "Küçük Adımlarla Büyük Başarılar!",
      'Küçük Kalpler İçin Büyük Fırsatlar!',
      'Oyunla Öğrenmenin Keyfini Çıkarın!',
      'Her Gün Yeni Bir Keşif!',
      'Öğrenmeye Açık Minik Zihinler İçin!',
    ];
    const word = words[currentWordIndex];
    let index = 0;
    const textArray: TextCharacter[] = word.split("").map((char, i) => ({ char, index: i }));

    setDisplayText(textArray);

    const typingInterval = setInterval(() => {
      setDisplayText(prevText =>
        prevText.map((item, i) =>
          i <= index ? { ...item, visible: true } : item
        )
      );
      index++;
      if (index > textArray.length - 1) {
        clearInterval(typingInterval);
        setTimeout(() => {
          setCurrentWordIndex(prevIndex => (prevIndex + 1) % words.length);
        }, 2000);
      }
    }, 100);
    return () => clearInterval(typingInterval);
  }, [currentWordIndex]);

  return (
    <div className={styles.container}>
      <video className={styles.backgroundVideo} autoPlay loop muted>
        <source src={video} type="video/mp4" />
      </video>
      <div className={styles.overlay}></div>
      <div className={styles.first}>
      <span className={styles.contentTitle}>ÖZEL LİVA AKADEMİ ANAOKULU</span>
        <h2 className={styles.typingContainer}>
          
          {displayText.map((item, index) => (
            <span
              key={index}
              style={{ animationDelay: `${index * 100}ms`, opacity: item.visible ? 1 : 0 }}
            >
              {item.char}
            </span>
          ))}
        </h2>
        <Link to={"/academic"}>
          <button className={styles.button}>Okulumuzu Keşfedin</button>
        </Link>
      </div>
      <ContactIcons />
    </div>
  );
};

export default HomePage;
